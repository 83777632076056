import React from "react";
import { GoogleLogout } from "react-google-login";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { GoogleClientId } from "../../config.json";
import { actionCreators } from "../../state";

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const { googleLogout } = bindActionCreators(actionCreators, dispatch);

  return (
    <div>
      <GoogleLogout
        clientId={GoogleClientId}
        buttonText="Logout"
        onLogoutSuccess={googleLogout}
      />
    </div>
  );
};

export default Logout;
