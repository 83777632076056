import { GoogleLoginResponse } from "react-google-login";
import { GoogleActionType } from "../action-types";
import { GoogleAction } from "../actions";

interface ILoginState {
  loggedIn: boolean;
  name?: string;
}

const defaultState: ILoginState = { loggedIn: false };

export const loginReducer = (
  state: ILoginState = defaultState,
  action: GoogleAction
): ILoginState => {
  switch (action.type) {
    case GoogleActionType.GOOGLE_LOGIN: {
      const loginResponse = action.payload as GoogleLoginResponse;

      console.log("Login successful, currentUser: ", loginResponse.profileObj);

      return {
        loggedIn: true,
        name: loginResponse.profileObj.name,
      };
    }
    case GoogleActionType.GOOGLE_LOGOUT: {
      console.log("Logout successful");

      return {
        loggedIn: false,
        name: undefined,
      };
    }
    default:
      return state;
  }
};
