import IVersionDto from "./IVersionDto";
import VersionDto from "./VersionDto";
import config from "../config.json";

export async function GetVersionDto(): Promise<IVersionDto> {
  try {
    const response = await fetch(config.ApiUrl);
    const body = await response.json();
    return body;
  } catch {
    return new VersionDto("?", "Error");
  }
}
