import IVersionDto from "./IVersionDto";

export default class VersionDto implements IVersionDto {
  constructor(version: string, commitHash: string) {
    this.version = version;
    this.commitHash = commitHash;
  }

  version: string;
  commitHash: string;
}
