import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { Dispatch } from "redux";
import { GoogleActionType } from "../action-types";
import { GoogleAction } from "../actions/index";

export const googleLogin = (
  googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline
) => {
  return async (dispatch: Dispatch<GoogleAction>) => {
    dispatch({
      type: GoogleActionType.GOOGLE_LOGIN,
      payload: googleResponse,
    });
  };
};

export const googleLogout = () => {
  return async (dispatch: Dispatch<GoogleAction>) => {
    dispatch({
      type: GoogleActionType.GOOGLE_LOGOUT,
    });
  };
};
