import React from "react";
import { useSelector } from "react-redux";
import "./App.css";
import VersionInfo from "../VersionInfo";
import Login from "../Login";
import Logout from "../Logout";
import { RootState } from "../../state/reducers";

const App: React.FC = () => {
  const loginState = useSelector((state: RootState) => state.loginReducer);

  const displayLoggedIn = function () {
    return (
      <>
        Logged in as {loginState.name}
        <Logout />
      </>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Calendar</h1>
        {loginState.loggedIn ? displayLoggedIn() : <Login />}
        <div className="App-footer">
          <VersionInfo />
        </div>
      </header>
    </div>
  );
};

export default App;
