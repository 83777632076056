import React from "react";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { GoogleClientId } from "../../config.json";
import { actionCreators } from "../../state";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { googleLogin } = bindActionCreators(actionCreators, dispatch);

  return (
    <div>
      <GoogleLogin
        clientId={GoogleClientId}
        buttonText="Login"
        onSuccess={googleLogin}
        onFailure={(res) => console.log("[Login Failed] res: ", res)}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={true}
      />
    </div>
  );
};

export default Login;
