import React from "react";
import config from "../../config.json";
import { useState, useEffect } from "react";
import { GetVersionDto } from "../../api/calendarApi";
import IVersionDto from "../../api/IVersionDto";

const VersionInfo: React.FC = () => {
  const [versionDto, setVersionDto] = useState<IVersionDto>();

  useEffect(() => {
    GetVersionDto().then(setVersionDto);
  }, []);

  return (
    <>
      <h3>Version</h3>
      <p>
        UI: {config.Version} ({config.CommitHash.substring(0, 8)})
      </p>
      <p>
        API: {versionDto?.version ?? "..."} (
        {versionDto?.commitHash.substring(0, 8) ?? "..."})
      </p>
    </>
  );
};

export default VersionInfo;
